<template>
  <div>
    <!-- form -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col sm="4">
            <b-form-group
              label="Логин"
              label-for="email"
            >
              <b-form-input
                v-model="userData.email"
                name="email"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Роль"
              label-for="role_id"
            >
              <v-select
                v-model="userData.roles"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="val => val.id"
                :get-option-label="option => option.name"
                :clearable="false"
                input-id="role_id"
              />
            </b-form-group>
          </b-col>
          <!-- new password -->
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="password"
            >
              <b-form-group
                label-for="account-new-password"
                label="Новый пароль"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="userData.password"
                    :state="getValidationState(validationContext)"
                    :type="passwordFieldTypeNew"
                    name="password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="retype-password"
              :rules="'equal:' + userData.password"
            >
              <b-form-group
                label-for="account-retype-new-password"
                label="Повторить пароль"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="userData['retype-password']"
                    :state="getValidationState(validationContext)"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              type="submit"
              :disabled="loading"
            >
              <b-spinner
                v-if="loading"
                small
                class="mr-50"
              />
              <span>{{ $t('Save Changes') }}</span>
            </b-button>
            <!-- <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
            >
              {{ $t('Reset') }}
            </b-button> -->
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput,
  BRow, BCol, BInputGroup, BInputGroupAppend,
  BFormInvalidFeedback, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required,
  equal,
} from '@validations'
import { ref } from '@vue/composition-api'
// import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInvalidFeedback,
    BSpinner,
    vSelect,

    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    api: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    required,
    equal,
    passwordFieldTypeNew: 'password',
    passwordFieldTypeRetype: 'password',
  }),
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
  },
  setup(props, { root }) {
    const roleOptions = ref([])

    store.dispatch('app-user/fetchRoles')
      .then(response => {
        roleOptions.value = response.data.data
      })
      .catch(() => {
        roleOptions.value = []
      })

    const loading = ref(false)
    const onSubmit = () => {
      loading.value = true
      store.dispatch('app-user/updateUser', {
        api: props.api,
        id: props.userData.id,
        userData: {
          email: props.userData.email,
          password: props.userData.password,
          roles: props.roles,
        },
      }).then(() => {
        loading.value = false

        root.$toast({
          component: ToastificationContent,
          props: {
            title: '',
            text: 'Аккаунт обновлен',
            icon: 'InfoIcon',
            variant: 'success',
          },
        })
      }).catch(() => {
        loading.value = false
      })
    }

    const resetuserData = () => {
      // eslint-disable-next-line no-param-reassign
      props.userData.password = ''
      // eslint-disable-next-line no-param-reassign
      props.userData['retype-password'] = ''
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      loading,
      roleOptions,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
