<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.profile.avatar && `${server}${userData.profile.avatar}`"
          :text="avatarText(userData.profile.fio)"
          size="90px"
          rounded
        />
      </template>
      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline" v-if="userData.profile.avatar">Обновить</span>
          <span class="d-none d-sm-inline" v-else>Загрузить</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
          @click="userData.profile.avatar = null"
        >
          <span class="d-none d-sm-inline">Удалить</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
    </b-media>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <b-row>

        <!-- Field: FIO -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="ФИО"
            label-for="fio"
          >
            <b-form-input
              id="fio"
              v-model="userData.profile.fio"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Город"
            label-for="city"
          >
            <b-form-input
              id="city"
              v-model="userData.profile.city"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Birth Date -->
        <b-col
          v-if="roleSlug !== 'superadmin' && roleSlug !== 'partner'"
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Дата рождения"
            label-for="dob"
          >
            <b-form-input
              id="dob"
              v-model="userData.profile.dob"
              v-mask="'##.##.####'"
              placeholder="дд.мм.гггг"
              type="text"
              trim
            />
          </b-form-group>
        </b-col>

        <!-- Field: phone -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Телефон"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="userData.profile.phone"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="userData.profile.email"
            />
          </b-form-group>
        </b-col>

        <!-- Field: parent_fio -->
        <b-col
          v-if="roleSlug === 'player'"
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Родитель"
            label-for="parent_fio"
          >
            <b-form-input
              id="parent"
              v-model="userData.profile.parent_fio"
            />
          </b-form-group>
        </b-col>

        <!-- Field: parent_phone -->
        <b-col
          v-if="roleSlug === 'player'"
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Телефон родителя"
            label-for="parent_phone"
          >
            <b-form-input
              id="parent_phone"
              v-model="userData.profile.parent_phone"
            />
          </b-form-group>
        </b-col>

      </b-row>
    </b-form>

    <b-card
      v-if="roleSlug === 'trainer'"
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Разрешения</span>
        </b-card-title>
      </b-card-header>
      <b-table-simple
        striped
        responsive
        class="mb-0"
      >
        <b-thead>
          <b-th></b-th>
          <b-th></b-th>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td>Отмечать посещения тренировок</b-td>
            <b-td>
              <b-form-checkbox
                v-model="userData.profile.set_visited"
                value="1"
                unchecked-value="0"
              />
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>

    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1 mt-2"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="updateUser()"
      :disabled="loading"
    >
      <b-spinner
        v-if="loading"
        small
        class="mr-50"
      />
      <span>Сохранить изменения</span>
    </b-button>

  </div>
</template>

<script>
import {
  BRow, BCol, BMedia, BAvatar, BForm,
  BFormGroup, BFormInput, BButton,
  BSpinner, BCard, BCardHeader, BCardTitle,
  BTableSimple, BThead, BTbody, BTh,
  BTr, BTd, BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import moment from 'moment'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
    BCard,
    BCardHeader,
    BCardTitle,
    BTableSimple,
    BThead,
    BTbody,
    BTh,
    BTr,
    BTd,
    BFormCheckbox,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    userRoles: {
      type: Array,
      required: true,
    },
    api: {
      type: String,
      required: true,
    },
  },
  setup(props, { root }) {
    const server = process.env.VUE_APP_SERVER
    const roleSlug = props.userRoles[0].slug

    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, () => {
      store.dispatch('app/uploadImage', {
        image: refInputEl.value.files[0],
      }).then(res => {
        if (res.image) {
          // eslint-disable-next-line no-param-reassign
          props.userData.profile.avatar = `storage/${res.image}`
        }
      })
    })

    const loading = ref(false)
    const updateUser = () => {
      loading.value = true
      store.dispatch('app-user/updateUser', {
        api: props.api,
        id: props.userData.profile.user_id,
        userData: {
          avatar: props.userData.profile.avatar,
          city: props.userData.profile.city,
          dob: props.userData.profile.dob,
          email: props.userData.profile.email,
          fio: props.userData.profile.fio,
          parent_fio: props.userData.profile.parent_fio,
          parent_phone: props.userData.profile.parent_phone,
          phone: props.userData.profile.phone,
          set_visited: props.userData.profile.set_visited,
        },
      })
        .then(() => {
          loading.value = false

          root.$toast({
            component: ToastificationContent,
            props: {
              title: '',
              text: 'Профиль обновлен',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
        })
        .catch(err => {
          // eslint-disable-next-line
          console.log(err)
          loading.value = false
        })
    }

    const inputDate = val => {
      // eslint-disable-next-line
      props.userData.profile.dob = moment(val).format('DD.MM.YYYY')
    }

    return {
      server,
      avatarText,
      refInputEl,
      previewEl,
      inputImageRenderer,
      loading,
      updateUser,
      roleSlug,
      inputDate,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
